<script>
    import validationMessages from '@/components/validations'
    import { required } from "vuelidate/lib/validators";

    export default {
        components:{
            validationMessages
        },
        props: ['item'],
        validations: {
            attributes: {
                $each: {
                    name: {
                        required
                    },
                    value: {
                        required
                    }
                },
            },
        },
        data() {
            return {
                showModal: false,
                submitted: false,
                attributes: [{name:'', value:''}],
                attribute_value: null,
                tryingToSubmit: false,
            };
        },
        methods: {
            addAttributes(){
                this.submitted = true
                this.$v.$touch()
                if (this.$v.$invalid) {
                    this.tryingToSubmit = false;
                    return;
                } else {
                    this.item['attributes'] = this.attributes
                    this.$emit('setMediaAttributes', this.item);
                    this.closeModal();
                }
            },
            addAttributeInput() {
                if(this.attributes.some(data => data.name.trim() === '')){
                    this.submitted = true
                    this.$v.$touch()
                }else{
                    this.attributes.push({ name: '', value:'' });
                    this.submitted = false
                }
            },
            removeAdvertisementMediaAttribute(index){
                this.attributes.splice(index, 1);
            },
            syncMediaAttributes(){
                if(Object.prototype.hasOwnProperty.call(this.item, 'attributes')){
                    this.attributes = []
                    this.item.attributes.forEach((value) => {
                        this.attributes.push({name: value.name, value: value.value})
                    });
                }
            },
            closeModal() {
                this.showModal = false;
                this.submitted = false;
                this.attributes = [{name:'', value:''}]
            },
        }
    };
</script>


<template>
    <b-modal @shown="syncMediaAttributes" id="add_advertisement_media_attributes" size="lg" v-model="showModal"
             title="Add New Advertisement Media Attribute" title-class="font-18" centered>

        <form @submit.prevent="addAttributes">
            <div class="row" v-for="(attribute, index) in attributes" :key="index">
                <div class="col-6">
                    <b-form-group label="Attribute Name" :label-for="'attributename_'+index" class="mb-3">
                        <b-form-input
                                v-model="attribute.name"
                                :id="'attributename_'+index"
                                :class="{
                             'is-invalid': submitted && $v.attributes.$each[index].name.$error,
                        }"
                        >
                        </b-form-input>
                        <validationMessages v-if="submitted" :fieldName="'Attribute Name'"
                                            :validationName="$v.attributes.$each[index].name"></validationMessages>
                    </b-form-group>
                </div>
                <div class="col-6">
                    <b-form-group label="Attribute Value" :label-for="'attributevalue_'+index" class="mb-3 d-inline-block">
                        <b-form-input
                                :id="'attributevalue_'+index"
                                v-model="attribute.value"
                                :class="{
                        'is-invalid': submitted && $v.attributes.$each[index].value.$error,
                    }"
                        >
                        </b-form-input>
                        <validationMessages v-if="submitted" :fieldName="'Attribute Value'"
                                            :validationName="$v.attributes.$each[index].value"></validationMessages>
                    </b-form-group>
                    <div v-if="index > 0 || attribute.name != '' " class="d-inline-block ms-3">
                        <a href="javascript:void(0);" title="Remove Attribute" class="text-danger" @click="removeAdvertisementMediaAttribute(index)">
                            <i class="uil uil-trash-alt font-size-18"></i>
                        </a>
                    </div>
                </div>

            </div>
            <div class="row">
                <div class="col-12">
                    <a class="btn btn-primary" @click="addAttributeInput">Add New Attribute</a>
                </div>
            </div>
        </form>
        <template v-slot:modal-footer>
            <b-button variant="light" @click="closeModal">Close</b-button>
            <b-button variant="primary" @click="addAttributes" :disabled="tryingToSubmit">
                <b-spinner v-show="tryingToSubmit" small></b-spinner>
                Submit
            </b-button>
        </template>
    </b-modal>

</template>