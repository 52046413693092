<script>
    import validationMessages from '@/components/validations'
    import Teams from '@/services/Teams'
    import { required } from "vuelidate/lib/validators";

    export default {
        components:{
            validationMessages
        },
        props: ['item','placement_type_id'],
        validations: {
            attributes: {
                $each: {
                    name: {
                        required
                    },
                    value: {
                        required
                    } ,
//                    selectedPlayer: {
//                        required
//                    }
                },
            },
            attributes_new: {
                $each: {
                    name: {
                        required
                    },
                    value: {
                        required
                    }
                },
            },
        },
        data() {
            return {
                showModal: false,
                submitted: false,
                attributes: [{name:'', value:''}],
                attributes_new: [],
//                attributes_new: [{name:'', value:''}],
                attribute_value: null,
                tryingToSubmit: false,
                selectedPlayer: '' ,
                selectedMatch: '' ,
                squad: [] ,
                fixtures : [] ,
                filteredMatches : {},
                playerNotSelected : false ,
                MatchNotSelected : false ,
                duplicateAttribute : false ,
                valueToRemove : '' ,
                NameToRemove : '' ,
            };
        },
        mounted() {

            this.getSquad();
            this.getFixtures();

        },
        methods: {

            hasDuplicateAttribute(name, value) {
                return this.attributes_new.some(attr => attr.name === name) && this.attributes_new.some(attr => attr.value === value);

            },

            addAttributes(){

                this.submitted = true

                if (!this.selectedPlayer) {
                    this.playerNotSelected = true;
                }
                if (!this.selectedMatch) {
                    this.MatchNotSelected = true;
                }

                const filteredAttributesNew = this.attributes_new.filter(newAttr => {
                    return !this.attributes.some(attr => attr.name === newAttr.name && attr.value === newAttr.value);
                });

                let mergedArray = this.attributes.concat(filteredAttributesNew);

//                const duplicate = this.attributes.some(attr => this.hasDuplicateAttribute(attr.name, attr.value));
//                if (duplicate) {
//                    this.duplicateAttribute = true;
//                } else {
//                    this.duplicateAttribute = false;
//                }


                this.$v.$touch()
                if (this.$v.$invalid) {
                    this.tryingToSubmit = false;
                    return;
                } else {
                    this.$emit('setAdvertisementAttributes', mergedArray);
                    this.closeModal();
                }
            },
            addAttributeInput() {

                if(this.attributes.some(data => data.name.trim() === '')){
                    this.submitted = true

                    if(this.placement_type_id === 10 || this.placement_type_id === 20 || this.placement_type_id === 15){
                        this.attributes_new.push({ name: '', value:'' });
                    } else  if(this.placement_type_id === 150 || this.placement_type_id === 160){
                        this.attributes_new.push({ name: '', value:'' });
                    }else{
                        this.$v.$touch()
                    }
                }else{
                    if(this.placement_type_id === 10 || this.placement_type_id === 20 || this.placement_type_id === 15){
                        this.attributes_new.push({ name: '', value:'' });
                    } else if(this.placement_type_id === 150 || this.placement_type_id === 160){
                        this.attributes_new.push({ name: '', value:'' });
                    }else{
                        this.attributes.push({ name: '', value:'' });
                        this.submitted = false
                    }

                }
            },



            removeAdvertisementAttribute(index,valueToRemove,NameToRemove){

                const indexToRemove = this.attributes_new.findIndex((attr) => {
                    return attr.name === NameToRemove && attr.value === valueToRemove;
                });

                this.attributes_new.splice(indexToRemove, 1);

                const indexToRemove1 = this.attributes.findIndex((attr) => {
                    return attr.name === NameToRemove && attr.value === valueToRemove;
                });

                this.attributes.splice(indexToRemove1, 1);

            },
            syncAdvertisementAttributes(){

                if(this.item.length > 0){
                    this.attributes = []
                    this.item.forEach((value) => {
                       this.attributes.push({name: value.name, value: value.value})
                   });
                }

            },
            getSquad() {
                const hardcodedParam = 'first_team';
                  Teams.getTeamSquad(hardcodedParam).then(response => {
                      let squad = response.data.squad[0].players;
                      this.squad = squad;

                  }).catch(error => {
                    console.error('Error fetching team squad:', error);
                });
            },
            async getFixtures() {
                try {
                    const response = await Teams.getFixtures();
                    this.fixtures1 = response.data.fixtures;

                    // Filter out  with "past" equal to "PAST"
                    this.filteredMatches = this.fixtures1.filter(obj => obj.past !== "PAST");

                } catch (error) {
                    console.error('Error fetching team fixtures:', error);
                }
            },
            onChangePlayer(){

               this.attributes[0].name = 'player_id';
               this.attributes[0].value = this.selectedPlayer;
               this.playerNotSelected = null ;

            },
            onChangeMatch(){

                this.attributes[0].name = 'match_id';
                this.attributes[0].value = this.selectedMatch;
                this.MatchNotSelected = null ;

            },
            closeModal() {
                this.showModal = false;
                this.submitted = false;
                this.attributes = [{name:'', value:''}]
            },
        }
    };
</script>


<template>
    <b-modal @shown="syncAdvertisementAttributes" id="add_advertisement_attributes" size="lg" v-model="showModal"
             title="Add New Advertisement Attribute" title-class="font-18" centered>

        <form @submit.prevent="addAttributes">

            <template v-if="placement_type_id === 10 || placement_type_id === 20 || placement_type_id === 15 ">

                <div class="col-sm-12 col-md-6 mb-sm-3 mb-md-0">
                    <label :for="'attributevalue_0'">Select a Player:</label>
                    <select :id="'attributevalue_0'" v-model="selectedPlayer" @change="onChangePlayer()" class="form-control form-select mb-4" type="select">
                        <option value="">Select a player</option>
                        <option v-for="player in squad" :key="player.id" :value="player.id" >
                            {{ player.first_name }} {{ player.last_name }}
                        </option>

                    </select>
                <p v-if="playerNotSelected || selectedPlayer === null" class="text-danger">Player Not Selected !</p>
                </div>

                <div class="row mb-3" v-for="(attribute, index) in attributes_new" :key="index">
                    <div class="col-sm-12 col-md-6 mb-sm-3 mb-md-0">
                        <!--{{index}}-->
                        <b-form-group label="Attribute Name" :label-for="'attributename_'+index">
                            <b-form-input
                                    v-model="attribute.name"
                                    :id="'attributename_'+index"
                                    :class="{
                             'is-invalid': submitted && $v.attributes_new.$each[index].name.$error,
                        }"
                            >
                            </b-form-input>
                            <validationMessages v-if="submitted" :fieldName="'Attribute Name'"
                                                :validationName="$v.attributes_new.$each[index].name"></validationMessages>
                        </b-form-group>
                    </div>
                    <div class="col-sm-12 col-md-6 mb-sm-3 mb-md-0">
                        <b-form-group label="Attribute Value" :label-for="'attributevalue_'+index" class="d-inline-block">
                            <b-form-input
                                    :id="'attributevalue_'+index"
                                    v-model="attribute.value"
                                    :class="{
                        'is-invalid': submitted && $v.attributes_new.$each[index].value.$error,
                    }"
                            >
                            </b-form-input>
                            <validationMessages v-if="submitted" :fieldName="'Attribute Value'"
                                                :validationName="$v.attributes_new.$each[index].value"></validationMessages>
                        </b-form-group>
                        <div v-if="index > 0 || attribute.name != '' " class="d-inline-block ms-3">

                            <a href="javascript:void(0);" title="Remove Attribute" class="text-danger" @click="removeAdvertisementAttribute(index,attribute.value,attribute.name)">
                                <i class="uil uil-trash-alt font-size-18"></i>
                            </a>
                        </div>
                    </div>
                    <!--<p v-if="duplicateAttribute" class="text-danger">Duplicate name and value.</p>-->

                </div>

            </template>

            <template v-if="placement_type_id === 150 || placement_type_id === 160  ">
                <div class="col-sm-12 col-md-6 mb-sm-3 mb-md-0">
                    <label :for="'attributevalue_1'">Select a Match:</label>
                    <select :id="'attributevalue_1'" v-model="selectedMatch" @change="onChangeMatch()" class="form-control form-select mb-4" type="select">
                        <option value="">Select Match</option>
                        <option v-for="match in filteredMatches" :key="match.match_id" :value="match.match_id" >
                            {{ match.home_team_short }} : {{ match.away_team_short }} -  {{match.date_formatted}}
                        </option>

                    </select>
                    <p v-if="MatchNotSelected || selectedMatch === null" class="text-danger">Match Not Selected !</p>
                </div>

                <div class="row mb-3" v-for="(attribute, index) in attributes_new" :key="index">
                    <div class="col-sm-12 col-md-6 mb-sm-3 mb-md-0">
                        <!--{{index}}-->
                        <b-form-group label="Attribute Name" :label-for="'attributename_'+index">
                            <b-form-input
                                    v-model="attribute.name"
                                    :id="'attributename_'+index"
                                    :class="{
                             'is-invalid': submitted && $v.attributes_new.$each[index].name.$error,
                        }"
                            >
                            </b-form-input>
                            <validationMessages v-if="submitted" :fieldName="'Attribute Name'"
                                                :validationName="$v.attributes_new.$each[index].name"></validationMessages>
                        </b-form-group>
                    </div>
                    <div class="col-sm-12 col-md-6 mb-sm-3 mb-md-0">
                        <b-form-group label="Attribute Value" :label-for="'attributevalue_'+index" class="d-inline-block">
                            <b-form-input
                                    :id="'attributevalue_'+index"
                                    v-model="attribute.value"
                                    :class="{
                        'is-invalid': submitted && $v.attributes_new.$each[index].value.$error,
                    }"
                            >
                            </b-form-input>
                            <validationMessages v-if="submitted" :fieldName="'Attribute Value'"
                                                :validationName="$v.attributes_new.$each[index].value"></validationMessages>
                        </b-form-group>
                        <div v-if="index > 0 || attribute.name != '' " class="d-inline-block ms-3">

                            <a href="javascript:void(0);" title="Remove Attribute" class="text-danger" @click="removeAdvertisementAttribute(index,attribute.value,attribute.name)">
                                <i class="uil uil-trash-alt font-size-18"></i>
                            </a>
                        </div>
                    </div>
                    <!--<p v-if="duplicateAttribute" class="text-danger">Duplicate name and value.</p>-->

                </div>

            </template>

            <template v-else>

            <div class="row mb-3" v-for="(attribute, index) in attributes" :key="index">

                  <div class="col-sm-12 col-md-6 mb-sm-3 mb-md-0">
                      <b-form-group label="Attribute Name" :label-for="'attributename_'+index">
                          <b-form-input
                                  v-model="attribute.name"
                                  :id="'attributename_'+index"
                                  :class="{
                             'is-invalid': submitted && $v.attributes.$each[index].name.$error,
                        }"
                          >
                          </b-form-input>
                          <validationMessages v-if="submitted" :fieldName="'Attribute Name'"
                                              :validationName="$v.attributes.$each[index].name"></validationMessages>
                      </b-form-group>
                  </div>
                  <div class="col-sm-12 col-md-6 mb-sm-3 mb-md-0">
                      <b-form-group label="Attribute Value" :label-for="'attributevalue_'+index" class="d-inline-block">
                          <b-form-input
                                  :id="'attributevalue_'+index"
                                  v-model="attribute.value"
                                  :class="{
                        'is-invalid': submitted && $v.attributes.$each[index].value.$error,
                    }"
                          >
                          </b-form-input>
                          <validationMessages v-if="submitted" :fieldName="'Attribute Value'"
                                              :validationName="$v.attributes.$each[index].value"></validationMessages>
                      </b-form-group>
                      <div v-if="index > 0 || attribute.name != '' " class="d-inline-block ms-3">
                          <a href="javascript:void(0);" title="Remove Attribute" class="text-danger" @click="removeAdvertisementAttribute(index,attribute.value,attribute.name)">
                              <i class="uil uil-trash-alt font-size-18"></i>
                          </a>
                      </div>
                  </div>

            </div>
            </template>
            <div class="row">
                <div class="col-12">
                    <a class="btn btn-primary" @click="addAttributeInput">Add New Attribute</a>
                </div>
            </div>
        </form>
        <template v-slot:modal-footer>
            <b-button variant="light" @click="closeModal">Close</b-button>
            <b-button variant="primary" @click="addAttributes" :disabled="tryingToSubmit">
                <b-spinner v-show="tryingToSubmit" small></b-spinner>
                Submit
            </b-button>
        </template>
    </b-modal>

</template>